import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import tw from "tailwind-styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { PrimaryText } from "@components";
import { CheckLanguage } from "@utils";
import { getAboutUs } from "@store";

function AboutUs() {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const aboutUsData = useSelector((state) => state.footers.aboutUsData);
  const [state, setState] = useState({
    loading: true,
  });

  useEffect(() => {
    let dateLanguage = CheckLanguage() || "en";
    dispatch(getAboutUs(`about-us?locale=${dateLanguage}`)).then((res) => {
      if (res?.payload?.status === 200 && res?.payload?.data) {
        setState((prev) => ({ ...prev, loading: false }));
      }
    });
  }, []);

  return (
    <Root>
      <PrimaryText className={title}>{t("footer.aboutUs")}</PrimaryText>
      {state.loading ? (
        <div className="w-full flex justify-center py-20">
          <CircularProgress sx={{ color: "#ffffff" }} size={50} thickness={5} />
        </div>
      ) : (
        <div className="break-words hyphens-auto text-textColor opacity-80">
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={aboutUsData.toString()}
          />
        </div>
      )}
    </Root>
  );
}

const Root = tw.div`
px-4
py-8
w-full
h-auto
whitespace-pre-line 
flex 
flex-col
`;

const title = `
text-3xl 
text-textColor 
font-bold 
font-[Cambay] 
text-center 
self-center 
mb-8 
underline 
underline-offset-8
`;

export default AboutUs;
