import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PersonIcon from "@mui/icons-material/Person";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import "../componentStyle.css";

function CustomInput(props) {
  const [showPassword, setShowPassword] = useState(false);

  const changeType = () => {
    setShowPassword(!showPassword);
  };

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#535754ad",
      },
    },
  });

  const Icons = () => {
    return (
      <span className="absolute inset-y-0 left-0 flex items-center pl-2 font-[Cambay]">
        <div className="p-1 focus:outline-none focus:shadow-outline">
          <ThemeProvider theme={theme}>
            {props.title === "Username" ? (
              <AccountBoxIcon fontSize="medium" color="secondary" />
            ) : props.title === "Email" ? (
              <EmailIcon fontSize="medium" color="secondary" />
            ) : props?.title === "Password" ||
              props?.title === "Confirm Password" ? (
              <VpnKeyIcon fontSize="medium" color="secondary" />
            ) : props?.title === "First Name" ||
              props?.title === "Last Name" ? (
              <PersonIcon fontSize="medium" color="secondary" />
            ) : (
              ""
            )}
          </ThemeProvider>
        </div>
      </span>
    );
  };

  const Secure_Icon = () => {
    return (
      <span className="absolute inset-y-0 right-0 flex items-center pr-2 font-[Cambay]">
        <div className="p-1 focus:outline-none focus:shadow-outline">
          <ThemeProvider theme={theme}>
            <button onClick={changeType}>
              {" "}
              {showPassword ? (
                <VisibilityOutlinedIcon fontSize="medium" color="secondary" />
              ) : (
                <VisibilityOffOutlinedIcon
                  fontSize="medium"
                  color="secondary"
                />
              )}
            </button>
          </ThemeProvider>
        </div>
      </span>
    );
  };

  return (
    <div className="w-full h-auto">
      <Root>
        <Icons />
        <input
          className={`${input_style} ${props.error ? "border-red-500" : ""} ${
            !props.secureText ? "pl-10 pr-2" : "px-10"
          }`}
          autoComplete="off"
          role="presentation"
          type={`${props.secureText && !showPassword ? "password" : "text"}`}
          placeholder={props?.title}
          value={props.value}
          onChange={props.onChange}
          maxLength={props.maxLength}
        />

        {props.secureText && <Secure_Icon />}
      </Root>

      <Error_Txt>
        {props.error
          ? props.passwordError
            ? "Password and Confirm Password does not match"
            : props.errorMessage
            ? props.errorMessage
            : "Please fill out this field."
          : ""}
      </Error_Txt>
    </div>
  );
}

const Root = tw.div`
relative 
text-gray-600 
focus:border-gray-500 
w-full 
h-auto 
mt-4 
border 
rounded-full
`;

const input_style = `
w-full 
py-2 
text-md 
bg-gray-200 
rounded-full 
focus:outline-none 
focus:bg-white 
border 
focus:border-gray-500
`;

const Error_Txt = tw.p`
text-red-500 
text-xs 
italic
`;

export default CustomInput;
