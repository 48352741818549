import { footerApi } from "@apiService";
import * as types from "./types";

export const getFootersData = (slug) => {
  return {
    type: types.GET_FOOTER_DATA,
    payload: footerApi.getFooters(slug),
  };
};

export const getPrivacyPolicy = (slug) => {
  return {
    type: types.GET_PRIVACY_POLICY,
    payload: footerApi.getFooterContent(slug),
  };
};

export const getCancelPolicy = (slug) => {
  return {
    type: types.GET_CANCEL_POLICY,
    payload: footerApi.getFooterContent(slug),
  };
};

export const getTermCondition = (slug) => {
  return {
    type: types.GET_TERM_CONDITION,
    payload: footerApi.getFooterContent(slug),
  };
};

export const getAboutUs = (slug) => {
  return {
    type: types.GET_ABOUT_US,
    payload: footerApi.getFooterContent(slug),
  };
};
