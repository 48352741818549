import { combineReducers } from "@reduxjs/toolkit";

import menu from "./menu/reducer";
import users from "./users/reducer";
import clubs from "./clubs/reducer";
import header from "./header/reducer";
import teeTime from "./teeTime/reducer";
import courses from "./courses/reducer";
import setting from "./setting/reducer";
import footers from "./footers/reducer";
import payment from "./payment/reducer";
import bookings from "./bookings/reducer";
import facilities from "./facilities/reducer";
import competition from "./competition/reducer";
import tournamentPayment from "./tournamentPayment/reducer";

const rootReducer = combineReducers({
  menu,
  users,
  clubs,
  header,
  teeTime,
  courses,
  footers,
  setting,
  payment,
  bookings,
  facilities,
  competition,
  tournamentPayment,
});

export default rootReducer;
