import React, { useReducer, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import tw from "tailwind-styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { PrimaryText, CustomButton, TostMessage } from "@components";
import { getPaymentDetail, cancelBooking, getClubsData } from "@store";

function CancelBooking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const clubData = useSelector((state) => state.clubs.clubsData);
  const facilitiesData = clubData?.data?.[0]?.attributes?.facility?.data;

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      alertMessage: false,
      alertMessageTxt: null,
      btnLoading: false,
      isLoading: true,
      paymentDetails: {},
      cancelToken: null,
    }
  );

  // useEffect(() => {
  //   dispatch(getClubsData());
  // }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("cancel-booking-id");
    if (token) {
      dispatch(getPaymentDetail(token)).then((res) => {
        setState({ isLoading: false, cancelToken: token });
        if (res?.payload?.data && res?.payload?.status === 200) {
          setState({ paymentDetails: res?.payload?.data });
        }
      });
    } else {
      navigate("/");
    }
  }, [location]);

  const cancel = () => {
    navigate("/");
  };

  const cancelBook = () => {
    setState({ btnLoading: true });
    const apiData = {
      bulkBookingId: state.cancelToken,
      facilityId: facilitiesData?.id,
      name: state.paymentDetails?.name,
      email: state.paymentDetails?.email,
    };
    dispatch(cancelBooking(apiData)).then((res) => {
      setState({ btnLoading: false });
      if (res?.payload?.data) {
        setState({
          alertMessage: true,
          alertMessageTxt: "Your Booking is successfully cancelled !",
        });
        setTimeout(() => {
          navigate("/");
        }, 4000);
      } else {
        res?.payload?.response?.data?.message
          ? setState({
              alertMessage: true,
              alertMessageTxt: res?.payload?.response?.data?.message,
            })
          : res?.payload?.response?.data?.error?.message
          ? setState({
              alertMessage: true,
              alertMessageTxt: res?.payload?.response?.data?.error?.message,
            })
          : setState({
              alertMessage: true,
              alertMessageTxt:
                "A generic error occurred on the Cancel Booking!",
            });
      }
    });
  };

  const DetailTxt = (props) => {
    return (
      <div
        className={`w-full h-[48px] flex items-center justify-start px-2 bg-primary rounded-lg `}
      >
        <PrimaryText className={`text-[17px] font-medium text-white `}>
          {props.title}
        </PrimaryText>
      </div>
    );
  };

  const DetailText = (props) => {
    return (
      <div
        className={`w-full h-[48px] flex items-center justify-start px-2 bg-gray-200 rounded-lg`}
      >
        <PrimaryText className={`text-[17px] truncate font-semibold`}>
          {props.title}
        </PrimaryText>
      </div>
    );
  };

  const ShowMessage = (props) => {
    return (
      <div className="w-full h-full flex justify-center items-center my-[20px]">
        <PrimaryText
          className={
            "center text-2xl text-textColor animate-pulse text-center "
          }
        >
          {props.title}
        </PrimaryText>
      </div>
    );
  };

  return (
    <Root>
      {state.isLoading ? (
        <div className={loadingView}>
          <CircularProgress sx={{ color: "#9c8053" }} size={50} thickness={5} />
        </div>
      ) : (
        <>
          {state.paymentDetails.id &&
          state.paymentDetails.paymentlogs.length ? (
            <>
              {state.paymentDetails?.id && (
                <PrimaryText className="text-textColor font-semibold text-[20px]">
                  {"CANCEL BOOKING"}
                </PrimaryText>
              )}
              <Component>
                {state.paymentDetails?.id && (
                  <div className="w-full h-auto mb-8">
                    <PrimaryText className="text-textColor text-[18px] ">
                      {`Hi ${state.paymentDetails.name}, let's review the details of your booking cancellation`}
                    </PrimaryText>
                  </div>
                )}

                {state.paymentDetails?.paymentlogs?.map((item, index) => (
                  <React.Fragment key={`cancel${index}`}>
                    <BookingView>
                      <div className="w-[50%] h-auto flex flex-col gap-y-4 items-center ">
                        <DetailTxt title={"Booking Date/Time"} />
                        {item?.course && <DetailTxt title={"Course"} />}
                        {item?.players && <DetailTxt title={"Players"} />}
                      </div>
                      <div className="w-[50%] h-auto flex flex-col gap-y-4 items-center ">
                        <DetailText
                          title={moment(item?.teeTime).format(
                            "YYYY-MM-DD / HH:mm "
                          )}
                        />
                        {item?.course && <DetailText title={item?.course} />}
                        {item?.players && <DetailText title={item?.players} />}
                      </div>
                    </BookingView>

                    {state.paymentDetails?.paymentlogs?.length - 1 !==
                      index && <Line />}
                  </React.Fragment>
                ))}

                {state.paymentDetails?.paymentlogs?.length > 0 && (
                  <BtnView>
                    <Cancle onClick={cancel}>
                      <PrimaryText className={"text-white255 text-md"}>
                        {"NO"}
                      </PrimaryText>
                    </Cancle>
                    <div className="md:w-[20%] sm:w-[40%] w-[47%] sm:ml-4 ml-2">
                      <CustomButton
                        onClick={cancelBook}
                        userLoading={state.btnLoading}
                        value={"YES"}
                        color="primary"
                      />
                    </div>
                  </BtnView>
                )}
              </Component>
              {state.alertMessageTxt && (
                <TostMessage
                  open={state.alertMessage}
                  onClose={() =>
                    setState({ alertMessage: false, alertMessageTxt: null })
                  }
                  title={state.alertMessageTxt}
                  type={"info"}
                />
              )}
            </>
          ) : (
            <ShowMessage title={"Bookings not found !"} />
          )}
        </>
      )}
    </Root>
  );
}

const Root = tw.div`
w-full
h-full
xl3:px-96
xl2:px-60
xl1:px-40
lg2:px-30
lg:px-20
md3:px-16
md2:px-10
md:px-6
sm:px-4
px-[5px]
py-8
`;

const loadingView = `
flex 
w-full 
h-auto 
justify-center 
items-center 
self-center 
justify-self-center 
py-4
`;

const Line = tw.div`
w-full
my-6
h-[1.1px] 
bg-[#C5C5C5] 
`;

const Component = tw.div`
w-full 
h-auto 
bg-boxBackground 
rounded-lg 
sm:p-4 sm2:p-2 
p-[3px] 
my-6
`;

const BtnView = tw.div`
w-full 
h-auto 
flex 
flex-row 
items-center 
justify-start 
gpa-4 
mt-14 
mb-4 
md:ml-6 ml-0
`;

const BookingView = tw.div`
w-full 
h-auto 
flex 
flex-row 
items-center 
justify-start 
sm:gap-x-4
sm2:gap-x-2
gap-x-[3px] 
`;

const Cancle = tw.button`
md:w-[20%] sm:w-[40%] w-[47%]
h-[40px]
pt-[4px]
rounded-[23px]
bg-gradient-to-r from-red-400 to-red255
hover:from-red255 hover:to-red255
flex
justify-center
items-center
webkitHighlight
`;

export default CancelBooking;
