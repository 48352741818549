import * as types from "./types";

const initialState = {
  allCompetitions: [],
  tournamentTeeTime: [],
  competitionTeeTime: [],
  bookedTournamentData: [],
  getBookedTournaments: [],
  competitionsLocalTeeTime: [],
  allTournaments: null,
  tournamentLoader: true,
  competitionLoader: true,
  singleCompetition: null,
  singleTournaments: null,
  competitionsTeeTime: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_COMPETITIONS: {
      return {
        ...state,
        allCompetitions: action?.payload?.data?.data,
      };
    }
    case types.GET_SINGLE_COMPETITION: {
      return {
        ...state,
        singleCompetition: action?.payload?.data,
      };
    }
    case types.COMPETITION_TEE_TIME: {
      return {
        ...state,
        competitionTeeTime: action?.payload?.data,
      };
    }
    case types.GET_ALL_TOURNAMENT: {
      return {
        ...state,
        allTournaments: action?.payload?.data?.data,
      };
    }
    case types.GET_SINGLE_TOURNAMENT: {
      return {
        ...state,
        singleTournaments: action?.payload?.data,
      };
    }
    case types.GET_TOURNAMENT_TEE_TIME: {
      return {
        ...state,
        tournamentTeeTime: action?.payload?.data?.data,
      };
    }
    case types.GET_COMPETITION_TEE_TIME: {
      return {
        ...state,
        competitionsTeeTime: action?.payload?.data?.data,
      };
    }
    case types.GET_COMPETITION_LOCAL_TEE_TIME: {
      return {
        ...state,
        competitionsLocalTeeTime: action?.payload,
      };
    }
    case types.BOOK_TOURNAMENT: {
      return {
        ...state,
        bookedTournamentData: action?.payload?.data,
      };
    }
    case types.GET_BOOK_TOURNAMENT: {
      return {
        ...state,
        getBookedTournaments: action?.payload?.data?.data,
      };
    }
    case types.TOURNAMENT_LOADER: {
      return {
        ...state,
        tournamentLoader: action?.payload,
      };
    }
    case types.COMPETITION_LOADER: {
      return {
        ...state,
        competitionLoader: action?.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
