import axios from "axios";
import moment from "moment";
import Config from "../config";

let instance = axios.create({
  baseURL: Config.SERVER_HOST,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 1000000,
});

// const token = localStorage.getItem('UserToken')
// let instanceToken = axios.create({
//   baseURL: Config.SERVER_HOST,
//   headers: {
//     "Content-Type": "application/json",
//     "Authorization": `Bearer ${token !== null ? token : ''}`,
//   },
//   timeout: 1000000,
// });

// let instanceBaseUrl = axios.create({
//   baseURL: Config.BASE_API_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
//   timeout: 1000000,
//   auth: {
//     username: Config.BASE_API_USERNAME,
//     password: Config.BASE_API_PASSWORD,
//   },
// });

const teesApi = {
  checkTeesApi: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_TEES}/${slug.facilitiesId}?bookingDate=${moment(
          slug.bookingDate
        ).format("DD-MM-YYYY")}&holes=${slug.holes}&event=${false}`
      );
      return res;
    } catch (err) {
      return err;
    }
  },

  getTeesPaginate: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_TEES_PAGINATE}/${slug.facilitiesId}/?bookingDate=${moment(
          slug.bookingDate
        ).format("DD-MM-YYYY")}&players=${slug.players}&holes=${
          slug.holes
        }&playerType=${slug.playerTypeId}&event=${false}&pageNumber=${
          slug.pageNumber
        }`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
};
const teeTimebooking = {
  getTeeTimeBooking: async (slug) => {
    try {
      const res = await instance.post(`${Config.TEE_TIME_BOOKING}`, slug);
      return res;
    } catch (err) {
      return err;
    }
  },
  cancelBooking: async (slug) => {
    try {
      const res = await instance.post(
        `${Config.TEE_TIME_BOOKING_CANCEL}`,
        slug
      );
      return res;
    } catch (err) {
      return err;
    }
  },
  getListingBooking: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_BOOKINGS}?fromDate=${slug.fromDate}&toDate=${slug.toDate}`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
};
const settingApi = {
  countDown: async (slug) => {
    try {
      const res = await instance.get(`${Config.SETTING}?populate=bgImage`);
      return res;
    } catch (err) {
      return err;
    }
  },
  county: async (slug) => {
    try {
      const res = await instance.get(`${Config.GET_COUNTY}?populate=*`);
      return res;
    } catch (err) {
      return err;
    }
  },
  fetchExchangeRate: async () => {
    try {
      const response = await fetch(
        `https://api.exchangerate-api.com/v4/latest/EUR`
      );
      const data = await response.json();
      const exchangeRate = data.rates["USD"];
      return exchangeRate;
    } catch (error) {}
  },
};
const authApi = {
  login: async (slug) => {
    try {
      const res = await instance.post(`${Config.LOGIN}`, slug);
      return res;
    } catch (err) {
      return err;
    }
  },

  register: async (slug) => {
    try {
      const res = await instance.post(`${Config.REGISTER}`, slug);
      return res;
    } catch (err) {
      return err;
    }
  },

  getUser: async (slug) => {
    try {
      const token = localStorage.getItem("UserToken");
      const res = await axios.get(`${Config.GET_USER}?populate=image`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token !== null ? token : ""}`,
        },
        timeout: 1000000,
      });
      return res;
    } catch (err) {
      return err;
    }
  },

  upDate: async (slug) => {
    try {
      const token = localStorage.getItem("UserToken");
      const res = await axios.put(
        `${Config.USER_UPDATE}/${slug.id}`,
        slug.apiData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token !== null ? token : ""}`,
          },
          timeout: 1000000,
        }
      );
      return res;
    } catch (err) {
      return err;
    }
  },

  uploadImage: async (slug) => {
    try {
      const res = await axios.post(Config.UPLOAD_IMAGE, slug, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return res;
    } catch (err) {
      return err;
    }
  },
  changePassword: async (slug) => {
    try {
      const token = localStorage.getItem("UserToken");
      const res = await axios.post(Config.CHANGE_PASSWORD, slug, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token !== null ? token : ""}`,
        },
      });
      return res;
    } catch (err) {
      return err;
    }
  },
  forgotPassword: async (slug) => {
    try {
      const token = localStorage.getItem("UserToken");
      const res = await axios.post(Config.FORGOT_PASSWORD, slug, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token !== null ? token : ""}`,
        },
      });
      return res;
    } catch (err) {
      return err;
    }
  },
  resetPassword: async (slug) => {
    try {
      const token = localStorage.getItem("UserToken");
      const res = await axios.post(Config.RESET_PASSWORD, slug, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token !== null ? token : ""}`,
        },
      });
      return res;
    } catch (err) {
      return err;
    }
  },
};
const paymentApi = {
  createCustomer: async (slug) => {
    try {
      const token = localStorage.getItem("UserToken");
      const res = await axios.post(`${Config.CREATE_CUSTOMER}`, slug, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token !== null ? token : ""}`,
        },
        timeout: 1000000,
      });
      return res;
    } catch (err) {
      return err;
    }
  },
  createPayment: async (slug) => {
    try {
      const token = localStorage.getItem("UserToken");
      const res = await axios.post(`${Config.CREATE_PAYMENT}`, slug, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token !== null ? token : ""}`,
        },
        timeout: 1000000,
      });
      return res;
    } catch (err) {
      return err;
    }
  },
  createStripeCustomer: async (slug) => {
    try {
      const res = await instance.post(`${Config.CREATE_STRIPE_CUSTOMER}`, slug);
      return res;
    } catch (err) {
      return err;
    }
  },
  createPaymentIntent: async (slug) => {
    try {
      const res = await axios.post(`${Config.CREATE_PAYMENT_INTENT}`, slug, {
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 1000000,
      });
      return res;
    } catch (err) {
      return err;
    }
  },
  paymentUpdateBookingApi: async (slug) => {
    try {
      const res = await axios.post(`${Config.PAYMENT_UPDATE_BOOKING}`, slug, {
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 1000000,
      });
      return res;
    } catch (err) {
      return err;
    }
  },
  updatePaymentStatus: async (id, slug) => {
    try {
      const res = await instance.patch(
        `${Config.UPDATE_PAYMENT_STATUS}/${id}`,
        slug
      );
      return res;
    } catch (err) {
      return err;
    }
  },
  userCard: async (slug) => {
    try {
      const token = localStorage.getItem("UserToken");
      const res = await axios.get(`${Config.USER_CARD}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token !== null ? token : ""}`,
        },
        timeout: 1000000,
      });
      return res;
    } catch (err) {
      return err;
    }
  },
  getPaymentDetails: async (slug) => {
    try {
      const res = await instance.get(`${Config.GET_PAYMENT_DETAIL}/${slug}`);
      return res;
    } catch (err) {
      return err;
    }
  },
};
const tournamentPaymentApi = {
  createTournamentPaymentIntent: async (slug) => {
    try {
      const res = await axios.post(
        `${Config.CREATE_TOURNAMENT_PAYMENT_INTENT}`,
        slug,
        {
          headers: {
            "Content-Type": "application/json",
          },
          timeout: 1000000,
        }
      );
      return res;
    } catch (err) {
      return err;
    }
  },
};
const headerApi = {
  getHeader: async (slug) => {
    try {
      const res = await instance.get(`${Config.GET_HEADER}?populate=logo`);
      return res;
    } catch (err) {
      return err;
    }
  },
};
const facilitiesApi = {
  getFacilities: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_FACILITIES}/${slug}/facility-detail`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
  getFacilitieDetails: async (slug) => {
    try {
      const res = await instance.get(`${Config.GET_FACILITIES_ON_SANDBOX}/:52`);
      return res;
    } catch (err) {
      return err;
    }
  },
};
const clubsApi = {
  getClubss: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_CLUBS}?populate=logo,image,facility.courses`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
};
const footerApi = {
  getFooters: async (slug) => {
    try {
      const res = await instance.get(`${Config.GET_FOOTER}?locale=${slug}`);
      return res;
    } catch (err) {
      return err;
    }
  },
  getFooterContent: async (slug) => {
    try {
      const res = await instance.get(`${Config.FOOTER_PAGE}/${slug}`);
      return res;
    } catch (err) {
      return err;
    }
  },
};
const menusApi = {
  getMenu: async (slug) => {
    try {
      const res = await instance.get(`${Config.GET_MENU}?populate=*`);
      return res;
    } catch (err) {
      return err;
    }
  },
};
const openCompetitionApi = {
  getAllCompetitions: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_COMPETITION}?populate=tournaments.courseId`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
  getSingleCompetition: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_COMPETITION}/${slug}?populate=*`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
  competitionTeeTime: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_TEES}/${slug.facilitiesId}/?bookingDate=${moment(
          slug.bookingDate
        ).format("YYYY-MM-DD")}&players=${slug.players}&holes=${slug.holes}`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
  getAllTournament: async (slug) => {
    try {
      const res = await instance.get(`${Config.GET_TOURNAMENT}?populate=*`);
      return res;
    } catch (err) {
      return err;
    }
  },
  getSingleTournament: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_TOURNAMENT}/${slug}?populate=*`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
  getTournamentTeeTime: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_TOURNAMENT_TEE_TIME}?populate=*`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
  getCompetitionsTeeTime: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_COMPETITION_TEE_TIME}/${
          slug.facilitiesId
        }/?bookingDate=${moment(slug.bookingDate).format(
          "DD-MM-YYYY"
        )}&players=${slug.players}&holes=${slug.holes}&playerType=${
          slug.playerTypeId
        }&event=${true}&type=${slug.type}`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
  bookTournament: async (slug) => {
    try {
      const res = await instance.post(`${Config.BOOK_TOURNAMENT}`, slug);
      return res;
    } catch (err) {
      return err;
    }
  },
  getBookedTournament: async (slug) => {
    try {
      const res = await instance.get(
        `${Config.GET_BOOK_TOURNAMENT}?populate=*`
      );
      return res;
    } catch (err) {
      return err;
    }
  },
};

export {
  headerApi,
  facilitiesApi,
  clubsApi,
  footerApi,
  menusApi,
  teeTimebooking,
  authApi,
  settingApi,
  teesApi,
  paymentApi,
  tournamentPaymentApi,
  openCompetitionApi,
};
