import React, { lazy } from "react";

const Players = lazy(() => import("@containers/players"));
const Tournament = lazy(() => import("@containers/tournament"));
const CheckOut = lazy(() => import("@containers/teeTimeCheckOut"));
const TeeTimeBooking = lazy(() => import("@containers/teeTimeBooking"));
const OpenCompetitions = lazy(() => import("@containers/openCompetitions"));
const CompetitionCheckOut = lazy(() =>
  import("@containers/competitionCheckOut")
);
const Cookies = lazy(() => import("@containers/cookies"));
const Profile = lazy(() => import("@containers/profile"));
const Setting = lazy(() => import("@containers/setting"));
const AboutUs = lazy(() => import("@containers/aboutUs"));
const PrivacyPolicy = lazy(() => import("@containers/privacyPolicy"));
const CancelBooking = lazy(() => import("@containers/cancelBooking"));
const ResetPassword = lazy(() => import("@containers/resetPassword"));
const ChangePassword = lazy(() => import("@containers/changePassword"));
const ForgetPassword = lazy(() => import("@containers/forgetPassword"));
const ChangeLanguage = lazy(() => import("@containers/changeLanguage"));
const TermAndCondition = lazy(() => import("@containers/termAndCondition"));

const routes = [
  {
    exact: true,
    isPublic: true,
    path: "/",
    name: "TeeTimeBooking",
    element: <TeeTimeBooking />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/tournament",
    name: "OpenCompetitions",
    element: <OpenCompetitions />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/tournament/:competitionId/open-competition",
    name: "Tournament",
    element: <Tournament />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/tournament/:competitionId/players",
    name: "Players",
    element: <Players />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/tournament/checkout",
    name: "CompetitionCheckOut",
    element: <CompetitionCheckOut />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/check-out",
    name: "CheckOut",
    element: <CheckOut />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/profile",
    name: "Profile",
    element: <Profile />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/setting",
    name: "Setting",
    element: <Setting />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/setting/changeLanguage",
    name: "ChangeLanguage",
    element: <ChangeLanguage />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/setting/change-password",
    name: "ChangePassword",
    element: <ChangePassword />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/forgot-password/",
    name: "ForgetPassword",
    element: <ForgetPassword />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/reset-password",
    name: "ResetPassword",
    element: <ResetPassword />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/cancel-booking",
    name: "CancelBooking",
    element: <CancelBooking />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/aboutUs",
    name: "AboutUs",
    element: <AboutUs />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/privacyPolicy",
    name: "PrivacyPolicy",
    element: <PrivacyPolicy />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/termsAndConditions",
    name: "TermsAndConditions",
    element: <TermAndCondition />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/cookie",
    name: "Cookies",
    element: <Cookies />,
  },
];

export default routes;
