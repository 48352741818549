import * as types from "./types";

const initialState = {
  paymentData: [],
  customerData: [],
  userCardData: [],
  paymentIntentData: [],
  stripeCustomerData: [],
  paymentBookingData: [],
  updatePaymentStatus: [],
  payLoader: false,
  paymentDetail: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_CUSTOMER: {
      return {
        ...state,
        customerData: action?.payload,
      };
    }
    case types.CREATE_PAYMENT: {
      return {
        ...state,
        paymentData: action?.payload,
      };
    }
    case types.CREATE_STRIPE_CUSTOMER: {
      return {
        ...state,
        stripeCustomerData: action?.payload,
      };
    }
    case types.CREATE_PAYMENT_INTENT: {
      return {
        ...state,
        paymentIntentData: action?.payload?.data,
      };
    }
    case types.PAYMENT_UPDATE_BOOKING: {
      return {
        ...state,
        paymentBookingData: action?.payload?.data,
      };
    }
    case types.UPDATE_PAYMENT_STATUS: {
      return {
        ...state,
        updatePaymentStatus: action?.payload?.data,
      };
    }
    case types.USER_CARD: {
      return {
        ...state,
        userCardData: action?.payload,
      };
    }
    case types.PAY_LOADER: {
      return {
        ...state,
        payLoader: action?.payload,
      };
    }
    case types.PAYMENT_DETAIL: {
      return {
        ...state,
        paymentDetail: action?.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
