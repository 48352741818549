import React from "react";
import tw from "tailwind-styled-components";
import { PrimaryText } from "@components";

const HeaderMenu = (props) => {
  const { btnClass, onClick, selectBtn } = props;

  return (
    <CustomButton
      className={btnClass}
      onClick={onClick}
      style={{ cursor: selectBtn ? "default" : "pointer" }}
    >
      <PrimaryText
        className={
          selectBtn
            ? "text-textColor font-[Cambay] font-semibold bg-primary px-2 rounded-lg pt-[6px]"
            : "text-textColor font-semibold font-[Cambay] hover:opacity-80 hover:shadow-xl drop-shadow-2xl"
        }
      >
        {props.children}
      </PrimaryText>
      {selectBtn && (
        <div className="w-[42px] h-[4px] bg-primary absolute mt-[60px] font-semibold" />
      )}
    </CustomButton>
  );
};

const CustomButton = tw.button`
w-auto
h-auto
flex
flex-col
items-center
justify-center
`;

export default HeaderMenu;
