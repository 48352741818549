import React, { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import tw from "tailwind-styled-components";
import { PrimaryText } from "@components";
import { CheckLanguage } from "@utils";
import Config from "../../config";

function WeatherView() {
  const { t } = useTranslation("common");
  const clubAddress = useSelector(
    (state) => state.courses?.clubCoursData?.attributes?.address
  );

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      players: 1,
      lat: 53.30775, // by default Ireland latitude
      long: -6.6251, // by default Ireland longitude
      weatherData: [],
      country: "",
      city: "",
      district: "",
    }
  );

  useEffect(() => {
    setState({
      lat: clubAddress?.latitude ? clubAddress?.latitude : 53.30775,
      long: clubAddress?.longitude ? clubAddress?.longitude : -6.6251,
    });
  }, [clubAddress]);
  // var formattedDate = new Date(updatedDate).toISOString().slice(0, 10);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?lat=${state.lat}&lon=${state.long}&format=json`
        );
        const data = response?.data;
        if (data?.address) {
          const country = data?.address?.country || "";
          const district =
            data?.address?.archipelago ||
            data?.address?.city_district ||
            data?.address?.road ||
            "";
          const city =
            data?.address?.city ||
            data?.address?.town ||
            data?.address?.village ||
            data?.address?.county ||
            "";
          setState({ country: country, city: city, district: district });
        }
      } catch (error) {}
    };

    if (state.lat && state.long) {
      fetchLocationData();
      axios
        .get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${state.lat}&lon=${state.long}&units=metric&appid=${Config.WEATHER_APPID}`
        )
        .then((response) => {
          setState({ weatherData: response?.data });
        });
    }
  }, [state.lat, state.long]);

  let dateLanguage = CheckLanguage() || "en";

  const Content = () => {
    return (
      <div className="w-full h-full flex flex-row items-center justify-between gap-x-[4px] overflow-hidden px-2">
        <IconView>
          <img
            src={`https://openweathermap.org/img/w/${state.weatherData?.weather[0].icon}.png`}
            className="w-full h-full"
            alt="icon"
          />
        </IconView>
        <DateView>
          <PrimaryText className={dateTxt}>
            {moment
              .unix(state.weatherData?.dt)
              .locale(dateLanguage)
              .format("dddd, MMMM D, YYYY")}
          </PrimaryText>
          <PrimaryText className={temperatureTxt}>
            {state.weatherData?.main?.temp.toFixed(1) + "°"}
          </PrimaryText>
          <PrimaryText className={windTxt}>
            {t("weather.w")} {(state.weatherData?.wind?.speed * 3.6).toFixed(2)}{" "}
            {"km/h"}
          </PrimaryText>
        </DateView>
        <SunSetView>
          <PrimaryText className={sunsetTxt}>
            {t("weather.sunrise")}
          </PrimaryText>
          <PrimaryText className={sunsetTxt}>
            {moment
              .unix(state.weatherData?.sys?.sunrise)
              .locale(dateLanguage)
              .format("HH:MM")}
          </PrimaryText>
          <PrimaryText className={sunsetTxt}>{t("weather.sunset")}</PrimaryText>
          <PrimaryText className={sunsetTxt}>
            {moment
              .unix(state.weatherData?.sys?.sunset)
              .locale(dateLanguage)
              .format("HH:MM")}
          </PrimaryText>
        </SunSetView>
      </div>
    );
  };

  return (
    <React.Fragment>
      {state.weatherData?.main && (
        <Container>
          <div className="w-full h-[20px] flex items-center justify-center">
            <PrimaryText className="text-[14px] text-center font-[Cambay] text-textColor">
              {`${state.city}, ${state.district} ${state.country}`}
            </PrimaryText>
          </div>
          <Content />
          <div className="w-full h-[1px] bg-textColor opacity-40"></div>
          <div className="w-full h-[20px] flex items-center justify-end pr-2">
            <PrimaryText className="text-[13px] text-end font-[Cambay] text-textColor ">
              <span className="opacity-70">{"provided by:"} </span>
              <a
                href="https://openweathermap.org"
                target="_blank"
                className=" hover:underline underline-offset-2 opacity-70 hover:opacity-100 hover:text-[#b8b5ff]"
              >
                {"  "}
                {"openweathermap.org"}
              </a>
            </PrimaryText>
          </div>
        </Container>
      )}
    </React.Fragment>
  );
}

const Container = tw.div`
w-full
my-[24px]
p-[4px]
bg-boxBackground
flex
gap-y-[3px]
rounded-2xl
shadow-xl
drop-shadow-xl
flex 
flex-col
items-between
justify-between
h-full
`;
const IconView = tw.div`
w-[70px] 
h-[70px]
flex
justify-center
items-end
`;
const DateView = tw.div`
w-auto 
h-auto 
flex 
flex-col 
justify-between 
items-center
`;
const dateTxt = `
text-md 
font-[Cambay]
font-semibold 
text-[15px]
text-textColor 
`;
const temperatureTxt = `
text-textColor 
text-4xl 
font-bold
font-[Cambay]
`;
const windTxt = `
text-textColor 
text-[15px]
font-[Cambay]
text-[0.80rem]
`;
const SunSetView = tw.div`
w-auto 
h-auto
flex 
flex-col 
justify-evenly 
items-start
gap-y-1
`;
const sunsetTxt = `
text-textColor
text-[15px]
font-semibold
font-[Cambay]
`;

export default WeatherView;
