import React, { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import tw from "tailwind-styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
import Tooltip from "@mui/material/Tooltip";
import { PrimaryText } from "@components";
import { getSelectedPlayerData, getClubsData } from "@store";
import closeBtn from "@assets/image/closeBtn.png";
import "../../containerStyle.css";

function BookingDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const addToCartData = useSelector((state) => state.bookings?.addToCartData);
  const clubData = useSelector((state) => state.clubs.clubsData);
  const paymentLoader = useSelector((state) => state.payment.payLoader);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      clubsData: clubData ? clubData?.data : [],
    }
  );

  useEffect(() => {
    setState({ clubsData: clubData?.data });
  }, [clubData]);

  useEffect(() => {
    let localdata = JSON.parse(localStorage.getItem("LocalCartData"));
    if (localdata?.length && !addToCartData?.length) {
      dispatch(
        getSelectedPlayerData({ localdata: localdata, localSave: true })
      );
      navigate("/");
    }
  }, [addToCartData]);

  const removeItem = (e) => {
    if (!paymentLoader) {
      dispatch(
        getSelectedPlayerData({
          props: e?.teeTime,
          price: e?.price,
          save: true,
        })
      );
      if (addToCartData?.length === 1) {
        localStorage.removeItem("AddToCartsData");
        navigate(-1);
      }
    }
  };

  const DetailText = (props) => {
    return (
      <div className={`${props.divClass}`}>
        <PrimaryText
          className={`text-md font-[Cambay] ${props.txtClass} text-textColor`}
        >
          {props.value}
        </PrimaryText>
      </div>
    );
  };

  const prices = addToCartData?.length
    ? addToCartData?.map((a) => a?.price?.publicRate?.amount)
    : "";
  const totalPrice = addToCartData?.length
    ? prices?.reduce((acc, curr) => acc + curr)
    : "";

  const PlayerDetail = () => {
    return (
      <div className="w-full xl1:max-h-[735px] max-h-[500px] overflow-y-auto custom-scroll">
        {addToCartData?.map((item, index) => (
          <React.Fragment key={`teeTimeDAta${index}`}>
            <div className="flex flex-row p-4 px-4">
              <div className="w-[50%] h-auto flex flex-col justify-start items-start">
                <DetailText
                  divClass="mb-[5px]"
                  txtClass="font-semibold"
                  value={moment(item?.teeTime?.teeTimeData?.time).format(
                    "DD/MM/YYYY - HH:mm"
                  )}
                />
                <DetailText
                  txtClass="opacity-70"
                  value={item?.teeTime?.teeTimeData?.course}
                />
                <DetailText
                  txtClass="opacity-70"
                  value={item?.price?.players + " " + t("players")}
                />
              </div>

              <div className="w-[50%] h-auto flex flex-col justify-start items-end">
                <div className="flex flex-col h-full justify-between items-end">
                  <Tooltip title={t("checkOut.booking.removeTeeTime")} arrow>
                    <button
                      onClick={() => removeItem(item)}
                      disabled={paymentLoader}
                    >
                      <img
                        src={closeBtn}
                        alt={"close"}
                        className="w-[25px] h-[25px] duration-500 hover:transform hover:rotate-90"
                      />
                    </button>
                  </Tooltip>
                  <DetailText
                    value={
                      item?.price
                        ? `${getSymbolFromCurrency(
                            item?.price?.price?.currency
                          )} ${item?.price?.publicRate?.amount}`
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <Line />

            {addToCartData?.length - 1 === index ? (
              <div className="w-full h-auto flex flex-row items-center p-4 px-4 bg-[#d9d9d975] rounded-b-lg mt-1">
                <div className="w-[50%] h-auto">
                  <PrimaryText className="text-textColor font-bold">
                    {t("checkOut.booking.total")}
                  </PrimaryText>
                </div>
                <div className="w-[50%] h-auto text-end">
                  <PrimaryText className="text-textColor text-[18px] font-bold">
                    {getSymbolFromCurrency(item?.price?.price?.currency) +
                      " " +
                      totalPrice}
                  </PrimaryText>
                </div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const ClubDetails = () => {
    return (
      <React.Fragment>
        {state?.clubsData?.length > 0 &&
          state.clubsData.map((item, index) => (
            <React.Fragment key={`clubs${index}`}>
              <div
                className="rounded-t-xl mb-10 bg-contain"
                style={{
                  background: `linear-gradient(to bottom, rgba(255,255,255,0.01) 60%, rgba(128,128,128,0.8) 100%), url(${item?.attributes?.image?.data?.attributes?.url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "230px",
                }}
              >
                <ClubImgView>
                  <ClubProfileView>
                    <img
                      alt="club"
                      src={`${item?.attributes?.logo?.data?.attributes?.url}`}
                      className="w-full h-full rounded-full"
                    />
                  </ClubProfileView>
                  <ClubNameView>
                    {item?.attributes?.city && (
                      <div className="flex flex-col">
                        <PrimaryText
                          className={addressTxt}
                        >{`${item?.attributes?.city}`}</PrimaryText>
                      </div>
                    )}
                    {item?.attributes?.name && (
                      <div className="w-full h-aout">
                        <PrimaryText className={userNameTxt}>
                          {item?.attributes?.name}
                        </PrimaryText>
                      </div>
                    )}
                    <div />
                  </ClubNameView>
                  <div />
                </ClubImgView>
              </div>
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  };

  return (
    <div className="sticky top-4">
      <div className="mb-[10px] lg:mt-[0px] mt-8">
        <PrimaryText className={title}>
          {t("checkOut.booking.bookDetails")}
        </PrimaryText>
      </div>
      <Container>
        <ClubDetails />
        <PlayerDetail />
      </Container>
    </div>
  );
}

const Line = tw.div`
w-[93%] 
h-[1.1px] 
bg-primary
ml-4
`;
const ClubImgView = tw.div`
flex 
flex-row 
w-full 
h-[100px] 
mt-[185px]
z-10 
items-center
justify-between
`;
const ClubProfileView = tw.div`
md:w-[80px]
w-[70px]
md:h-[80px]
h-[70px] 
ml-2
flex 
justify-center 
items-center
`;
const ClubNameView = tw.div`
w-auto 
h-full
flex 
flex-col
justify-between 
items-center
`;
const userNameTxt = `
text-xl 
font-[Cambay] 
font-semibold 
text-textColor
`;
const addressTxt = `
text-textColor 
text-sm 
text-center
font-[Cambay]
`;
const Container = tw.div`
w-full 
h-auto 
flex flex-col 
bg-boxBackground 
rounded-xl 
shadow-xl
`;

const title = `
text-textColor 
text-lg 
font-semibold 
uppercase
`;

export default BookingDetails;
