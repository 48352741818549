import { authApi } from "@apiService";
import * as types from "./types";

export const userLogin = (slug) => {
  return {
    type: types.USER_LOGIN,
    payload: authApi.login(slug),
  };
};

export const userRegister = (slug) => {
  return {
    type: types.USER_REGISTER,
    payload: authApi.register(slug),
  };
};

export const userLogOut = (slug) => {
  return {
    type: types.USER_LOGOUT,
    payload: slug,
  };
};

export const getUser = (slug) => {
  return {
    type: types.GET_USER,
    payload: authApi.getUser(slug),
  };
};

export const userUpDate = (slug) => {
  return {
    type: types.USER_UPDATE,
    payload: authApi.upDate(slug),
  };
};

export const uploadImage = (slug) => {
  return {
    type: types.UPLOAD_IMAGE,
    payload: authApi.uploadImage(slug),
  };
};

export const changePassword = (slug) => {
  return {
    type: types.CHANGE_PASSWORD,
    payload: authApi.changePassword(slug),
  };
};

export const forgotPassword = (slug) => {
  return {
    type: types.FORGOT_PASSWORD,
    payload: authApi.forgotPassword(slug),
  };
};

export const resetPassword = (slug) => {
  return {
    type: types.RESET_PASSWORD,
    payload: authApi.resetPassword(slug),
  };
};
