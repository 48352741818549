import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { PrimaryText } from "@components";
import UpdateForm from "./widgets/updateForm";

function Profile() {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const loginData = useSelector((state) => state.users.login);
  const registerData = useSelector((state) => state.users.register);
  const userStatus = useSelector((state) => state.users.status);

  useEffect(() => {
    const users = localStorage.getItem("UserData");
    if (users === null) {
      navigate("/");
    }
  }, [loginData, registerData, userStatus]);

  return (
    <Root>
      <BackBtn>
        <ArrowBackIosNewIcon fontSize="small" sx={{ color: "white" }} />
        <div
          onClick={() => navigate(-1)}
          className="ml-[5px] hover:cursor-pointer"
        >
          <PrimaryText className="text-textColor font-semibold">
            {t("checkOut.back")}
          </PrimaryText>
        </div>
      </BackBtn>
      <UpdateForm />
    </Root>
  );
}

const Root = tw.div`
w-full
h-full 
flex 
flex-col
items-center 
justify-center 
xl:px-32 lg:px-16 
md:px-6 sm2:px-4 px-2 
mb-8
`;

const BackBtn = tw.div`
w-full 
flex 
flex-row
items-center
h-auto 
mt-2
`;

export default Profile;
