import { remove } from "@utils";
import * as types from "./types";

const initialState = {
  login: null,
  status: false,
  register: null,
  userData: null,
  userToken: null,
  userImage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOGIN: {
      if (action?.payload?.data) {
        localStorage.setItem("UserToken", action?.payload?.data?.jwt);
      }
      return {
        ...state,
        register: null,
        login: action?.payload,
        status: !state.status,
      };
    }
    case types.USER_REGISTER: {
      if (action?.payload?.data) {
        localStorage.setItem("UserToken", action?.payload?.data?.jwt);
      }
      return {
        ...state,
        login: null,
        register: action?.payload,
        status: !state.status,
      };
    }
    case types.CHANGE_PASSWORD: {
      return {
        ...state,
        login: null,
      };
    }
    case types.FORGOT_PASSWORD: {
      return {
        ...state,
      };
    }
    case types.RESET_PASSWORD: {
      return {
        ...state,
      };
    }
    case types.GET_USER: {
      if (action?.payload?.data) {
        localStorage.setItem(
          "UserData",
          JSON.stringify({ user: action?.payload?.data })
        );
        return {
          ...state,
          userData: action?.payload,
          status: !state.status,
        };
      } else {
        return state;
      }
    }
    case types.USER_UPDATE: {
      return {
        ...state,
        login: action?.payload,
        register: null,
      };
    }
    case types.UPLOAD_IMAGE: {
      return {
        ...state,
        userImage: action?.payload,
      };
    }
    case types.USER_LOGOUT: {
      localStorage.removeItem("UserData");
      localStorage.removeItem("UserToken");
      remove("Language");
      return {
        ...state,
        register: null,
        login: null,
        status: !state.status,
      };
    }
    default:
      return state;
  }
};

export default reducer;
