import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  PrimaryText,
  CustomModal,
  TostMessage,
  CustomButton,
} from "@components";
import { userLogOut } from "@store";
import { forgotPassword } from "../../store/users/action";

function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation("common");

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: "",
      emailError: false,
      btnLoading: false,
      successModal: false,
      alertMessage: false,
      alertMessageTxt: null,
    }
  );

  const closeModal = () => {
    dispatch(userLogOut());
    i18n.changeLanguage("en");
    setState({ successModal: false });
  };

  const submit = () => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!reg.test(state.email)) {
      setState({
        emailError: true,
      });
    } else {
      setState({
        emailError: false,
        btnLoading: true,
      });
      dispatch(
        forgotPassword({
          email: state.email,
        })
      ).then((res) => {
        setState({ btnLoading: false });
        if (res?.payload?.data && res?.payload?.status === 200) {
          setState({ successModal: true });
        } else {
          res?.payload?.data?.raw?.message
            ? setState({
                alertMessage: true,
                alertMessageTxt: res?.payload?.data?.raw?.message,
              })
            : res?.payload?.response?.data?.error?.message
            ? setState({
                alertMessage: true,
                alertMessageTxt: res?.payload?.response?.data?.error?.message,
              })
            : res?.payload?.response?.data?.message
            ? setState({
                alertMessage: true,
                alertMessageTxt: res?.payload?.response?.data?.message,
              })
            : setState({
                alertMessage: true,
                alertMessageTxt:
                  "A generic error occurred on the Change Password !",
              });
        }
      });
    }
  };

  const CustomInput = (props) => {
    return (
      <div className=" h-auto flex flex-col items-center justify-center mt-5">
        <span className="text-[14px] text-textColor text-center w-[60%] font-[Cambay]">
          {
            "Enter the email address associated with your account, we'll send you a link to reset your password."
          }
        </span>
        <div className="md:w-[75%] sm:w-[85%] w-full h-auto flex flex-col relative mt-5 justify-center">
          <input
            type="email"
            placeholder={props.title}
            className={textInput}
            value={props.value}
            onChange={props.onChange}
            maxLength={props.maxLength}
          />

          <p className="text-red-500 text-[14px] italic mt-1">
            {props.error ? "Please fill out the email field correctly." : ""}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Root>
      <BackBtn>
        <ArrowBackIosNewIcon fontSize="small" sx={{ color: "white" }} />
        <div
          onClick={() => navigate(-1)}
          className="ml-[5px] hover:cursor-pointer"
        >
          <PrimaryText className="text-textColor text-[16px]">
            {t("checkOut.back")}
          </PrimaryText>
        </div>
      </BackBtn>
      <PrimaryText className="text-textColor font-semibold text-[20px]">
        {"FORGOT PASSWORD"}
      </PrimaryText>
      <Container>
        <PrimaryText className="text-textColor text-[18px] h-auto">
          {"Forgot your password?"}
        </PrimaryText>

        <div className={email_View}>
          {CustomInput({
            title: "Email",
            value: state.email,
            onChange: (event) => {
              setState({
                email: event.target.value,
                emailError: false,
              });
            },
            error: state.emailError,
            maxLength: 50,
          })}
        </div>
        <div className={btn_View}>
          <div className="md:w-[20%] sm:w-[40%] w-[47%] sm:ml-4 sm:mr-4">
            <CustomButton
              onClick={submit}
              userLoading={state.btnLoading}
              value={"Continue"}
              color="primary"
            />
          </div>
        </div>
        <span className="text-[14px] text-textColor text-center mt-[30px]">
          {"Didn't receive the link? "}
          <button
            onClick={submit}
            className="text-[14px] text-[#c7a76f] font-[Cambay] hover:underline"
          >
            {"Resend"}
          </button>
        </span>
      </Container>
      <CustomModal
        openModal={state.successModal}
        closeModal={closeModal}
        title={"Kindly check email!"}
        description={
          "A link has been sent to your email address associated with us to change your account password"
        }
      />
      {state.alertMessageTxt && (
        <TostMessage
          open={state.alertMessage}
          onClose={() =>
            setState({ alertMessage: false, alertMessageTxt: null })
          }
          title={state.alertMessageTxt}
          type={"info"}
        />
      )}
    </Root>
  );
}

const Root = tw.div`
w-full
h-full
xl3:px-60
xl2:px-40
xl1:px-36
lg2:px-30
lg:px-20
md3:px-16
md2:px-10
md:px-6
sm:px-4
px-2
py-8
`;

const Container = tw.div`
w-full 
h-auto flex flex-col 
bg-boxBackground 
rounded-lg 
sm2:p-4 p-2 
my-6 
items-center
`;

const email_View = `
h-auto 
flex md2:flex-row flex-col 
items-center 
gap-4 
lg:px-8 md:px-4 sm:px-2 
px-[4px]
`;

const btn_View = `
w-full 
h-auto 
flex flex-row 
items-center justify-center 
gpa-4 
mt-6 
mb-4 md:ml-6 ml-0 md:mr-6 mr-0
`;

const BackBtn = tw.div`
w-full
flex
flex-row
items-center
h-auto
mb-8
`;

const textInput = `
w-full
bg-gray-200
text-textColor2 
border-[2px] 
rounded-lg
py-[10px]
px-4 
leading-tight 
focus:outline-none 
border-gray-200
shadow-[0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)]
focus:border-[black]
placeholder:text-[#757680]
`;

export default ForgetPassword;
