import React from "react";
import { useSelector } from "react-redux";
import tw from "tailwind-styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { PrimaryText } from "@components";
import "../componentStyle.css";

function CustomButton(props) {
  const isLoading = useSelector((state) => state.teeTime.showCompleteLoder);

  return (
    <Root>
      <button
        onClick={props.onClick}
        type="button"
        disabled={props.disabled}
        className={`${btn_Style} ${
          props.color ? "bg-primary" : "bg-textColor"
        } ${
          props.btnLoading
            ? "cursor-auto"
            : props.color
            ? "hover:bg-primary2"
            : "hover:opacity-60"
        } ${props.disabled ? "bg-gray182a cursor-wait" : ""}`}
      >
        {(isLoading && props.btnLoading) || props.userLoading ? (
          <CircularProgress
            sx={{ color: "#0000009e" }}
            size={25}
            thickness={5}
          />
        ) : (
          <PrimaryText
            className={`${
              props.color ? "text-white255" : "text-textColor2"
            } truncate px-2 font-semibold ${props?.txtStyle}`}
          >
            {props.value}
          </PrimaryText>
        )}
      </button>
    </Root>
  );
}

const Root = tw.div`
flex flex-col 
webkitHighlight 
shadow-md 
rounded-[23px]
`;

const btn_Style = `
rounded-[23px] 
pt-[10px] 
pb-[5px] 
text-center 
items-center 
flex flex-row 
justify-center 
shadow-md 
webkitHighlight
`;

export default CustomButton;
