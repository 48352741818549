import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
// import posthog from "posthog-js";
import App from "./App";
import AppLoader from "./components/loader";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/configureStore";
import common_en from "./translations/en/index.json";
import common_fr from "./translations/fr/index.json";
import common_ge from "./translations/ge/index.json";
import "./index.css";

// const api_Key = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;

// posthog.init(api_Key, {
//   api_host: "https://us.i.posthog.com",
//   person_profiles: "identified_only",
// });

const root = ReactDOM.createRoot(document.getElementById("root"));

i18next.init({
  lng: "en",
  debug: false,
  resources: {
    en: {
      common: common_en,
    },
    fr: {
      common: common_fr,
    },
    ge: {
      common: common_ge,
    },
  },
});

root.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="w-screen h-screen center">
              <AppLoader />
            </div>
          }
        >
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
