import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import tw from "tailwind-styled-components";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PrimaryText } from "@components";
import { userUpDate, uploadImage, getUser } from "@store";
import profileLogo from "@assets/image/profile.jpeg";
import UserSuccessModal from "./userSuccessModal";
import "../../containerStyle.css";

function UpdateForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const userStatus = useSelector((state) => state.users.status);
  const userDetails = useSelector((state) => state.users.userData);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      userName: "",
      gender: "",
      telePhone: "",
      dob: null,
      town: "",
      street: "",
      zipCode: "",
      companyName: "",
      image: "",
      imageUrl: "",
      imageData: "",
      isLoading: false,
      errorMessage: null,
      firstNameError: false,
      lastNameError: false,
      someChange: false,
      modal: false,
    }
  );

  useEffect(() => {
    const users = localStorage.getItem("UserData");
    if (users !== null) {
      const userData = JSON.parse(localStorage.getItem("UserData"));
      setState({
        id: userData?.user?.id,
        firstName: userData?.user?.firstName ? userData?.user?.firstName : "",
        lastName: userData?.user?.lastName ? userData?.user?.lastName : "",
        userName: userData?.user?.username,
        email: userData?.user?.email,
        telePhone: userData?.user?.phone ? userData?.user?.phone : "",
        gender: userData?.user?.gender ? userData?.user?.gender : "",
        street: userData?.user?.street ? userData?.user?.street : "",
        companyName: userData?.user?.companyName
          ? userData?.user?.companyName
          : "",
        town: userData?.user?.city ? userData?.user?.city : "",
        zipCode: userData?.user?.zipCode ? userData?.user?.zipCode : "",
        dob: userData?.user?.dob
          ? userData?.user?.dob === "Invalid Date"
            ? null
            : moment(userData?.user?.dob)
          : null,
        imageData: userData?.user?.image?.url ? userData?.user?.image?.url : "",
      });
    }
  }, [userDetails, userStatus]);

  const apiData = {
    firstName: state.firstName,
    lastName: state.lastName,
    gender: state.gender,
    phone: state.telePhone,
    companyName: state.companyName,
    city: state.town,
    street: state.street,
    zipCode: state.zipCode,
    dob: state.dob ? moment(state.dob).format("YYYY-MM-DD") : null,
  };

  const upDate = (e) => {
    e.preventDefault();
    if (state.firstName && state.lastName) {
      if (state.someChange) {
        setState({ isLoading: true });
        uploadData();
        dispatch(userUpDate({ apiData: apiData, id: state.id })).then((res) => {
          setState({ isLoading: false });
          if (res?.payload?.data && res?.payload?.status === 200) {
            dispatch(getUser()).then((res) => {
              setState({ modal: true, errorMessage: null });
            });
          } else {
            setState({
              modal: true,
              errorMessage: res?.payload?.response?.data?.error,
            });
          }
        });
      } else {
        setState({ modal: true, errorMessage: null });
      }

      setState({
        firstNameError: false,
        lastNameError: false,
      });
    } else {
      setState({
        firstNameError: !state.firstName,
        lastNameError: !state.lastName,
      });
    }
  };

  const closeModal = () => {
    if (state.someChange) {
      setState({
        modal: false,
        someChange: false,
        errorMessage: null,
      });
      navigate("/");
    } else {
      setState({
        modal: false,
        someChange: false,
        errorMessage: null,
      });
    }
  };

  const onChangeImage = (event) => {
    const { target } = event;
    const { files } = target;
    if (files && files[0]) {
      let reader = new FileReader();
      reader.onloadstart = () => setState({ image: files[0] });
      reader.onload = (event) => {
        setState({ imageData: event.target.result, someChange: true });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const uploadData = () => {
    if (state.image) {
      const formData = new FormData();
      formData.append("files", state.image);
      formData.append("ref", "plugin::users-permissions.user");
      formData.append("refId", state.id);
      formData.append("field", "image");
      dispatch(uploadImage(formData)).then(() => {
        dispatch(getUser());
      });
    }
  };

  const TextInput = (props) => {
    return (
      <div className="w-full ">
        <label className={input_title}>{props.title} </label>
        <input
          placeholder={props.title}
          readOnly={props.readonly}
          className={`${txtInput} ${
            props.error ? "border-[red]" : "border-gray-200"
          } `}
          type={"text"}
          value={props.value}
          onChange={props.onChange}
          maxLength={props.maxLength}
          autoComplete="off"
          role="presentation"
          required={props.require}
        />
        <p className="text-red-500 text-xs italic mb-[12px]">
          {props.error ? "Please fill out this field." : ""}
        </p>
      </div>
    );
  };

  const Form = () => {
    return (
      <FormView>
        <form onSubmit={upDate}>
          <PrimaryText className="text-textColor">
            {t("profile.personalDetail")}
          </PrimaryText>
          <div className="pt-4 lg:px-8 sm:px-2 px-2">
            <div className="flex flex-row mb-4 items-center">
              <div className="flex flex-col items-center justify-center gap-y-2">
                <p className="text-[15px] text-textColor font-[Cambay]">
                  {t("profile.profilePhote")}
                </p>
                <RoundedImage src={state.imageData || profileLogo} />
              </div>
              <input
                type={"file"}
                id={"file"}
                className="hidden"
                accept="Image/*"
                onChange={onChangeImage}
              />
              <div className="flex items-center justify-center ml-6 mt-[30px]">
                <Label htmlFor={"file"}>
                  <p className="text-[15px] font-semibold font-[Cambay] pt-1">
                    {t("profile.addPhoto")}
                  </p>
                </Label>
              </div>
            </div>

            <div className={inputview}>
              {TextInput({
                title: `${t("profile.fName")}`,
                value: `${state.firstName}`,
                onChange: (event) => {
                  const regex = /^[a-zA-Z\b]+$/;
                  if (
                    event.target.value === "" ||
                    regex.test(event.target.value)
                  ) {
                    setState({
                      firstName: event.target.value,
                      firstNameError: false,
                      someChange: true,
                    });
                  }
                },
                maxLength: 30,
                error: state.firstNameError,
                require: true,
              })}
              {TextInput({
                title: `${t("profile.lName")}`,
                value: `${state.lastName}`,
                onChange: (event) => {
                  const regex = /^[a-zA-Z\b]+$/;
                  if (
                    event.target.value === "" ||
                    regex.test(event.target.value)
                  ) {
                    setState({
                      lastName: event.target.value,
                      lastNameError: false,
                      someChange: true,
                    });
                  }
                },
                maxLength: 30,
                error: state.lastNameError,
                require: true,
              })}
            </div>

            <InputView>
              <div className="w-full h-auto">
                <span className="text-[15px] text-textColor font-[Cambay]">
                  {" "}
                  {t("profile.dob")}
                </span>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{ width: "100%", ckbagroundColor: "#E5E7EB" }}
                    slotProps={{
                      textField: {
                        size: "medium",
                        sx: {
                          width: "100%",
                          backgroundColor: "#E5E7EB",
                          marginBottom: "10px",
                        },
                      },
                    }}
                    value={state.dob}
                    onChange={(newValue) =>
                      setState({ dob: newValue, someChange: true })
                    }
                    format="DD-MM-YYYY"
                    disableFuture
                  />
                </LocalizationProvider>
              </div>
              {TextInput({
                title: `${t("profile.phone")}`,
                value: `${state.telePhone}`,
                onChange: (event) => {
                  const regex = /^[0-9+-.\b]+$/;
                  if (
                    event.target.value === "" ||
                    regex.test(event.target.value)
                  ) {
                    setState({
                      telePhone: event.target.value,
                      someChange: true,
                    });
                  }
                },
                maxLength: 25,
              })}
            </InputView>

            <InputView>
              {TextInput({
                title: `${t("profile.userName")}`,
                value: `${state.userName}`,
                onChange: (event) => {},
                readonly: true,
              })}
              {TextInput({
                title: `${t("profile.emailId")}`,
                value: `${state.email}`,
                onChange: (event) => {},
                readonly: true,
              })}
            </InputView>

            <InputView>
              <div className="w-full h-auto">
                <div className="">
                  <span className="text-md text-textColor font-[Cambay]">
                    {" "}
                    {t("profile.gender")}
                  </span>
                </div>
                <RadioGroup
                  row
                  sx={{ marginBottom: "20px" }}
                  value={state.gender}
                  onChange={(event) =>
                    setState({ gender: event.target.value, someChange: true })
                  }
                >
                  <FormControlLabel
                    sx={{ color: "white" }}
                    value="male"
                    control={<Radio />}
                    label={t("profile.male")}
                  />
                  <FormControlLabel
                    sx={{ color: "white" }}
                    value="female"
                    control={<Radio />}
                    label={t("profile.female")}
                  />
                  <FormControlLabel
                    sx={{ color: "white" }}
                    value="other"
                    control={<Radio />}
                    label={t("profile.other")}
                  />
                </RadioGroup>
              </div>
            </InputView>
          </div>
          <PrimaryText className="text-textColor">
            {t("profile.address")}
          </PrimaryText>
          <div className="py-4 lg:px-8 sm:px-2 px-2">
            <div className={inputview}>
              {TextInput({
                title: t("profile.street"),
                value: `${state.street}`,
                maxLength: 30,
                onChange: (event) => {
                  setState({
                    street: event.target.value,
                    someChange: true,
                  });
                },
              })}
              {TextInput({
                title: t("profile.companyName"),
                value: `${state.companyName}`,
                maxLength: 45,
                onChange: (event) => {
                  setState({
                    companyName: event.target.value,
                    someChange: true,
                  });
                },
              })}
            </div>

            <InputView>
              {TextInput({
                title: t("profile.townCity"),
                value: `${state.town}`,
                maxLength: 25,
                onChange: (event) => {
                  setState({
                    town: event.target.value,
                    someChange: true,
                  });
                },
              })}
              {TextInput({
                title: t("profile.postCode"),
                value: `${state.zipCode}`,
                maxLength: 25,
                onChange: (event) => {
                  setState({
                    zipCode: event.target.value,
                    someChange: true,
                  });
                },
              })}
            </InputView>

            <BtnView>
              <Cancle onClick={() => navigate("/")}>
                <PrimaryText className={"text-white255 text-lg"}>
                  {t("checkOut.booking.cancel")}
                </PrimaryText>
              </Cancle>
              <Submit type="submit">
                {state.isLoading ? (
                  <CircularProgress
                    sx={{ color: "#0000009e" }}
                    size={25}
                    thickness={5}
                  />
                ) : (
                  <PrimaryText className={"text-white255 text-lg"}>
                    {t("profile.update")}
                  </PrimaryText>
                )}
              </Submit>
            </BtnView>
          </div>
        </form>
      </FormView>
    );
  };

  return (
    <div className="w-full h-full ">
      <div className="w-full h-auto pl-1 pt-4">
        <PrimaryText className="text-textColor font-semibold">
          {t("profile.profile")}
        </PrimaryText>
      </div>
      {Form()}
      <UserSuccessModal
        openModal={state.modal}
        closeModal={closeModal}
        isChange={state.someChange}
        errorMessage={state.errorMessage}
      />
    </div>
  );
}

const RoundedImage = tw.img`
h-[66px] 
w-[66px] 
rounded-full 
border-2 border-primary
`;
const input_title = `
text-[15px] 
text-textColor 
font-[Cambay] 
mb-2 
flex flex-row 
opacity-70
`;
const FormView = tw.div`
w-full 
h-auto 
flex 
flex-col 
bg-boxBackground 
rounded-xl 
shadow-xl 
mt-2
py-2
lg:px-4 px-2

`;
const Label = tw.label`
h-[32px] 
w-auto
px-4 
truncate
border 
border-gray-200 
bg-gray-200
rounded-3xl 
center 
cursor-pointer 
flex 
items-center 
justify-center
`;
const InputView = tw.div`
w-full 
h-auto 
flex 
md2:flex-row 
flex-col 
items-center 
justify-evenly 
lg:gap-x-8
sm:gap-x-4 
gap-x-1
`;
const inputview = `
w-full 
h-auto 
flex 
md2:flex-row 
flex-col 
items-start 
justify-evenly 
lg:gap-x-8
sm:gap-x-4 
gap-x-1 
md:py-0 
py-4
`;

const BtnView = tw.div`
flex 
flex-row 
justify-between 
items-cetner
w-full 
h-[46px] 
mt-[30px]
`;

const Cancle = tw.button`
xl:w-[20%]
lg:w-[25%]
md:w-[30%]
sm:w-[35%]
w-[40%]
h-[44px]
rounded-[23px]
bg-gradient-to-r from-red255 to-[#ff3c4966]
hover:from-red255 hover:to-red255
flex
justify-center
items-center
webkitHighlight
`;
const Submit = tw.button`
xl:w-[20%]
lg:w-[25%]
md:w-[30%]
sm:w-[35%]
w-[40%]
h-[44px]
rounded-[23px]
bg-gradient-to-r from-primary to-primary2
hover:from-primary hover:to-primary
flex
justify-center
items-center
hover:cursor-pointer
webkitHighlight
`;

const txtInput = `
w-full 
bg-gray-200
text-textColor2 
border-[2px] 
rounded-lg
py-[11px]
px-4 
leading-tight 
focus:outline-none 
shadow-[0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)]
focus:border-[black]
placeholder:text-[#757680]
`;

export default UpdateForm;
