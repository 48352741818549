import { openCompetitionApi } from "@apiService";
import * as types from "./types";

export const allCompetitions = (slug) => {
  return {
    type: types.GET_ALL_COMPETITIONS,
    payload: openCompetitionApi.getAllCompetitions(slug),
  };
};

export const singleCompetition = (slug) => {
  return {
    type: types.GET_SINGLE_COMPETITION,
    payload: openCompetitionApi.getSingleCompetition(slug),
  };
};

export const competitionTeeTime = (slug) => {
  return {
    type: types.COMPETITION_TEE_TIME,
    payload: openCompetitionApi.competitionTeeTime(slug),
  };
};

export const allTournament = (slug) => {
  return {
    type: types.GET_ALL_TOURNAMENT,
    payload: openCompetitionApi.getAllTournament(slug),
  };
};
export const singleTournament = (slug) => {
  return {
    type: types.GET_SINGLE_TOURNAMENT,
    payload: openCompetitionApi.getSingleTournament(slug),
  };
};
export const getTournamentTeeTime = (slug) => {
  return {
    type: types.GET_TOURNAMENT_TEE_TIME,
    payload: openCompetitionApi.getTournamentTeeTime(slug),
  };
};
export const getCompetitionTeeTime = (slug) => {
  return {
    type: types.GET_COMPETITION_TEE_TIME,
    payload: openCompetitionApi.getCompetitionsTeeTime(slug),
  };
};
export const getCompetitionLocalTeeTime = (slug) => {
  return {
    type: types.GET_COMPETITION_LOCAL_TEE_TIME,
    payload: slug,
  };
};
export const bookTournament = (slug) => {
  return {
    type: types.BOOK_TOURNAMENT,
    payload: openCompetitionApi.bookTournament(slug),
  };
};
export const getBookedTournament = (slug) => {
  return {
    type: types.GET_BOOK_TOURNAMENT,
    payload: openCompetitionApi.getBookedTournament(slug),
  };
};

export const tournamentLoader = (slug) => {
  return {
    type: types.TOURNAMENT_LOADER,
    payload: slug,
  };
};

export const competitionLoader = (slug) => {
  return {
    type: types.COMPETITION_LOADER,
    payload: slug,
  };
};
