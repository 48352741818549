import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { PrimaryText } from "@components";
import { getSelectedPlayerData, allCompetitions } from "@store";
import userProfile from "@assets/image/userProfile.jpg";
import "../../containerStyle.css";

function CompetitionCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const allCompetitionsData = useSelector(
    (state) => state.competition?.allCompetitions
  );

  const [state, setState] = useState({
    loader: false,
    confirmId: null,
    loading: false,
    is_Active: false,
  });

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    dispatch(allCompetitions()).then((res) => {
      setState((prev) => ({ ...prev, loading: false }));
      const t_Data = res?.payload?.data?.data;
      if (t_Data?.length) {
        const active_Tournamnet = t_Data.find((e, i) => {
          return e?.attributes?.status === "active";
        });
        if (active_Tournamnet) {
          setState((prev) => ({ ...prev, is_Active: true }));
        } else {
          setState((prev) => ({ ...prev, is_Active: false }));
        }
      }
    });
  }, []);

  const goTournament = (e) => {
    setState((prev) => ({ ...prev, loader: true, confirmId: e?.id }));
    localStorage.removeItem("AddToCartsData");
    dispatch(getSelectedPlayerData({ save: false }));
    navigate(`/tournament/${e?.id}/open-competition`);
    window.scrollTo(0, 0);
  };

  const ShowMessage = (props) => {
    return (
      <div className="w-full h-full flex justify-center items-center my-[20px]">
        <PrimaryText
          className={"center text-xl text-white255 animate-pulse text-center "}
        >
          {props.title}
        </PrimaryText>
      </div>
    );
  };

  return (
    <>
      {allCompetitionsData?.length > 0 && state.is_Active === true && (
        <Competition_View>
          <PrimaryText className="text-3xl text-white255 font-bold font-[Cambay] text-center">
            {t("competition.seeOur")}
          </PrimaryText>
          <PrimaryText className="text-md leading-tight text-white255 font-[Cambay] text-center mt-[6px]">
            {t("competition.clickOn")}
          </PrimaryText>
        </Competition_View>
      )}

      {state.loading ? (
        <div className="flex h-full w-full pt-[70px] pb-[70px] justify-center">
          <CircularProgress sx={{ color: "#ffffff" }} size={50} thickness={5} />{" "}
        </div>
      ) : !state.is_Active ? (
        <ShowMessage title={t("competition.noCompetion")} />
      ) : allCompetitionsData?.length ? (
        allCompetitionsData?.map((item, index) => (
          <React.Fragment key={`competitions${index}`}>
            {item?.attributes?.status === "active" ? (
              <div className="w-full h-auto rounded-lg ">
                <Component onClick={() => goTournament(item)}>
                  <div className={detailView}>
                    <div className={img_view}>
                      <img
                        src={
                          item?.attributes?.image?.data?.attributes?.url
                            ? `${item?.attributes?.image?.data?.attributes?.url}`
                            : userProfile
                        }
                        alt="img"
                        className="w-full h-full border border-[#969696] rounded-md"
                      />
                    </div>
                    <div className="w-fit pl-[4%]">
                      <PrimaryText className={title_txt}>
                        {item?.attributes?.title}
                      </PrimaryText>
                      <br />
                      {item?.attributes?.description && (
                        <PrimaryText className={description_txt}>
                          {item?.attributes?.description}
                        </PrimaryText>
                      )}
                      <br />
                      {item?.attributes?.holes && (
                        <PrimaryText className={hole_txt}>
                          {item?.attributes?.holes + t("tournament.Holes")}
                        </PrimaryText>
                      )}
                    </div>
                  </div>
                  {item?.attributes?.tournaments?.data[0]?.attributes?.courseId
                    ?.data?.attributes?.courseName && (
                    <CourseView>
                      <PrimaryText className="text-md text-textColor2 font-semibold font-[Cambay] px-2">
                        {t("competition.course") +
                          item?.attributes?.tournaments?.data[0]?.attributes
                            ?.courseId?.data?.attributes?.courseName}
                      </PrimaryText>
                    </CourseView>
                  )}
                </Component>
              </div>
            ) : (
              <></>
            )}
          </React.Fragment>
        ))
      ) : (
        <ShowMessage title={t("competition.noCompetion")} />
      )}
    </>
  );
}

const Component = tw.div`
h-auto
min-h-[90px]
w-full
flex
flex-wrap
gap-x-[3px] gap-y-4
items-center
justify-between
bg-white
mt-4
mb-2
px-4
py-2
rounded-lg
hover:cursor-pointer
hover:opacity-80
`;
const Competition_View = tw.div`
w-full 
h-auto 
flex flex-col 
justify-between items-center
`;
const detailView = `
w-auto 
h-auto 
flex 
flex-row 
items-center 
justify-start
`;
const CourseView = tw.div`
w-auto
h-auto
flex 
items-center 
justify-center 
rounded-sm
`;
const img_view = `
md:w-[75px] sm:w-[65px] w-[65px] 
md:h-[65px] sm:h-[55px] h-[55px] 
min-w-[65px] max-w-[75px] 
min-h-[55px] max-h-[65px] 
flex
`;
const title_txt = `
font-[Cambay] 
text-[18px] 
font-semibold 
capitalize
`;
const description_txt = `
text-md 
font-[Cambay] 
leading-3 
text-[#969696] 
capitalize
`;
const hole_txt = `
text-md 
font-[Cambay] 
leading-3 
text-[#969696]
`;

export default CompetitionCard;
