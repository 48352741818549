import React from "react";
import tw from "tailwind-styled-components";
import CompetitionBox from "./competitionBox";

function CompentitionView() {
  return (
    <Component>
      <div className={boxView}>
        <CompetitionBox />
      </div>
    </Component>
  );
}

const Component = tw.div`
w-full
h-auto
flex
flex-col
`;
const boxView = `
h-auto
w-full
flex
flex-col
rounded-2xl
shadow-xl
drop-shadow-xl
sm:px-10
px-4
py-4
bg-boxBackground
`;

export default CompentitionView;
