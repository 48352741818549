import { settingApi } from "@apiService";
import * as types from "./types";

export const getCountDown = (slug) => {
  return {
    type: types.GET_COUNT_DOWN,
    payload: settingApi.countDown(slug),
  };
};

export const getCounty = (slug) => {
  return {
    type: types.GET_COUNTY,
    payload: settingApi.county(slug),
  };
};

export const getUSDPrice = () => {
  return {
    type: types.GET_USD_PRICE,
    payload: settingApi.fetchExchangeRate(),
  };
};

export const getBGimage = () => {
  return {
    type: types.GET_BG_IMAGE,
    payload: settingApi.countDown(),
  };
};
